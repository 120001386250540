import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { BulkActionButton, CreateWithIcon } from '../../../component/buttonComponent/ButtonComponent';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../component/title/Title';
import { useApiData } from '../../../config/reducer/Modules';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import SearchInput from '../../../component/searchInput/SearchInput';
import FilterButton from '../../../component/filterButton/FilterButton';
import GeofenceTableEmptyState from '../../../component/tableEmptyState/GeofenceTableEmptyState';
import { APIGetGeofence } from '../../../config/restAPI/FetchAPI';
import SnackBar from '../../../component/snackbar/Snackbar';

import Table from '../../../component/table/Table';

import TruckIcon from '../../../image/truckIcon.svg';
import './geofence.css';

const seperator = [
    <img loading="lazy" src={TruckIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Geofence</div>,
];

export default function Geofence(props) {
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const navigate = useNavigate();
    const location = useLocation();
    const { apiResponse } = useApiData();

    // console.log('location test', location);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [triggerSnackbar, settriggerSnackbar] = useState(false); // trigger snackbar
    const [textTitleSnackbar, setTextTitleSnackbar] = useState(null);
    const [textSubSnackbar, setTextSubSnackbar] = useState(null);
    const [moduleSnackbar, setModuleSnackbar] = useState(null);
    const [geofenceData, setGeofenceData] = useState([]);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [showTable, setShowTable] = useState(true);
    const [emptyState, setEmptyState] = useState(false);
    const bulkActionDisabled = selectedRowCount <= 1;
    const perPage = 10;
    const defautlParams = { pageNumber: 1 }; // this to default params for fetchAPI

    // console.log('apiresponseeee', apiResponse);

    const inventoryNavigationList = [
        {
            text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.asset,
        },
        // {
        //     text: 'Beacons', value: 2, path: '/inventory/beacons', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.beacon,
        // },
        {
            text: 'Geofence', value: 3, path: '/inventory/geofence', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.geofence,
        },
        {
            text: 'Connectivity', value: 4, path: '/inventory/connectivity', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.connectivity,
        },
    ];

    const fetchGeofence = async (params) => {
        try {
            const result = await APIGetGeofence(setLoading, token, agencyID, params);
            // console.log(result);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                // console.log('dataaaaa', data)
                if (data && data.length > 0) {
                    const reducer = data.map((x) => ({
                        oid: x.oid,
                        geofenceName: x.geofenceName,
                        associatedTags: x.associatedTags,
                        isEnabled: x.isEnabled,
                        notification: x.notification,
                        lastTrigger: x.lastTrigger,
                        geofenceArea: x.geofenceArea.length > 0 ? x.geofenceArea.map((i) => ({
                            oid: i?.properties?.oid,
                            name: i?.properties?.name,
                            area: [{
                                type: i.type,
                                properties: {
                                    id: i?.properties?.oid,
                                    name: i?.properties?.name,
                                },
                                id: i?.properties?.oid,
                                geometry: i?.geometry,
                            }],
                        })) : [],
                        notifiedUsers: x.notifiedUsers.length > 0 ? x.notifiedUsers.map((j) => ({
                            id: `${j.userFirstName} ${j.userLastName}`,
                            name: j.userEmail,
                            url: j.profileImageUrl ? j.profileImageUrl : null,
                            channel: j?.channels?.length > 0 ? j.channels : [],
                            oid: j.oid,
                        })) : [],
                    }));
                    // console.log('test', reducer);
                    setShowTable(true);
                    setEmptyState(false);
                    setGeofenceData(reducer);
                    setTotalRows(totalData); // this for enable the next button for pagination
                    // console.log('redicer', reducer);
                } else {
                    setEmptyState(true);
                    setShowTable(false);
                    setGeofenceData([]);
                }
                setLoading(false);
            } else if (result.status === 204) {
                setEmptyState(true);
                setShowTable(false);
                setGeofenceData([]);
                setLoading(false);
            } else { // error return from API
                setEmptyState(true);
                setShowTable(false);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    // click next button in table
    const handlePageChange = (page) => {
        const pagePerNumber = {
            pageNumber: page,
        };
        fetchGeofence(pagePerNumber);
     };

    // this to go to details page, (Add)
    const detailsPage = () => {
        navigate('/inventory/geofence/details', { replace: true });
    };

    // this onChange for search text
    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    // this to render fetchGeofence once deleteGeofence
    useEffect(() => {
        if (location.state) {
            if (location.state.fetchAPICall) {
                fetchGeofence(defautlParams);
                navigate(location.pathname, { replace: true }); // this to reset the state in location state
            }
            if (location.state.snackbarStatus) {
                let timeout;
                settriggerSnackbar(true);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    settriggerSnackbar(false);
                }, 4000);
                setTextTitleSnackbar(location.state.snackbarTitle);
                setTextSubSnackbar(location.state.snackbarSub);
                setModuleSnackbar(location.state.snackbarStatus);
                navigate(location.pathname, { replace: true });
            }
        }
    }, [location.state]);

    // this useEffect for search policies
    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
          }
          // this to call api and setTimeout to 0.2 sec
          setSearchTimeout(
            setTimeout(() => {
              const searchingValue = {
                searchKey: searchText,
                pageNumber: 1,
              };
            //   console.log(searchingValue);
              fetchGeofence(searchingValue);
            }, 200),
          );
          return () => clearTimeout(searchTimeout); // this also to clear time out
    }, [searchText]);

    // this useEffect for onPageLoad
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        if (token) {
            fetchGeofence(defautlParams);
        }
        setNavigtionClick(3);
        setNavigationState('inventory');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            <div className="geofence-container" style={{ width: windowSize.innerWidth - 280 }}>
                <ListNavigationTab list={inventoryNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                <div className="geofence-header">
                    <BreadCrumbs customSeperator={seperator} />
                    <Title title="Geofence" subtitle="Manage policies that enable preemptive alerting or action triggers for devices/assets around geofence areas. " titleSize="1.875rem" />
                </div>
                <div className="geofence-body">
                    <div className="geofence-body-filter">
                        <div className="geofence-bulk-button-container">
                            {/* <BulkActionButton label="Bulk action" width="8.1875rem" color="white" disable={bulkActionDisabled} /> */}
                            <SearchInput containerWidth="25rem" text="Search" containerHeight="22px" value={searchText} onChangeText={onChangeSearchText} />
                        </div>
                        <div className="geofence-input-filter-button">
                            {/* <FilterButton height="44px" /> */}
                            {/* <ExportCSVButton label="Export View" buttonHeight="44px" /> */}
                            <CreateWithIcon label="Create policy" module="policy" buttonHeight="44px" onClickFunction={detailsPage} />
                        </div>
                    </div>
                    {showTable && (
                            <div className={loading ? 'geofence-body-text-loading' : 'geofence-body-text'}>
                                <Table
                                  selectedRowCount={selectedRowCount}
                                  setSelectedRowCount={setSelectedRowCount}
                                  tableData={geofenceData}
                                  pagination
                                  module="geofence"
                                  handlePageChange={handlePageChange}
                                  loading={loading}
                                  totalRows={totalRows}
                                  backgroundColor="#F9FAFB"
                                />
                            </div>
                    )}
                    {emptyState && (
                        <div className="geofence-body-empty">
                            <GeofenceTableEmptyState detailsPage={detailsPage} />
                        </div>
                    )}
                </div>
            </div>
            {
                triggerSnackbar && (
                <div className="geofence-snackbar-container">
                    <SnackBar state={triggerSnackbar} setState={settriggerSnackbar} title={textTitleSnackbar} subtitile={textSubSnackbar} module={moduleSnackbar} action={moduleSnackbar} />
                </div>
                )
            }

        </>

    );
}
