import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { DateObject } from 'react-multi-date-picker';
import { useApiData } from '../../../config/reducer/Modules';
import { APIGetDevices, APIGetTripReport } from '../../../config/restAPI/FetchAPI';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import Autocomplete from '../../../component/autocomplete/Autocomplete';
import {
    LiveMapButton,
    CreateWithIcon,
} from '../../../component/buttonComponent/ButtonComponent';
import SnackBar from '../../../component/snackbar/Snackbar';
import DateRangePicker from '../../../component/dateRangePicker/DateRangePicker';
import './ODAnalytics.css';

const navigationList = [
    {
        text: 'Trips',
        value: 5,
        path: '/insights/odAnalytics',
        enabled: true,
    },
];

const ODAnalytics = () => {
    const { state } = useLocation();
    const { apiResponse } = useApiData();
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [loading, setLoading] = useState(false);

    // Default States
    const [uuid, setUuid] = useState('' || state?.uuid);

    // Search timer state
    const [searchTimeout, setSearchTimeout] = useState(null);

    // Date Picker Values
    const [date, setDate] = useState([]);
    const [inputValue, setInputValue] = useState('Select Dates');
    const [minDateRange, setMinDateRange] = useState(new DateObject().subtract(1, 'year'));
    const [maxDateRange, setMaxDateRange] = useState(new Date());
    const [dateRange, setDateRange] = useState('');

    // Asset Device Values
    const [listAsset, setListAsset] = useState([]);
    const [assetSelectionList, setAssetSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [loadAsset, setLoadAsset] = useState(false); // for getSearchAsset loader

    // Error Notification
    const [incorrectCredentials, setIncorrectCredentials] = useState(false);

    const generateDisabled = date.length <= 1 || Object.keys(assetSelectionList).length <= 1;

    const assetPerformanceNavigationList = [
        {
            text: 'Asset Performance', value: 1, path: '/insights/assetPerformance', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.assetPerformance,
        },
        // {
        //     text: 'IOrail', value: 2, path: '/insights/IOrail', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.iorail,
        // },
        // {
        //     text: 'IOroad', value: 3, path: '/insights/IOroad', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.ioroad,
        // },
        // {
        //     text: 'Audits', value: 4, path: '/insights/audits', enabled: true,
        // },
        {
            text: 'O/D Analytics', value: 5, path: '/insights/odAnalytics', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.odAnalytics,
        },
    ];

    const reset = () => {
        setDateRange('');
        setMinDateRange(null);
        setMaxDateRange(new Date());
        setInputValue('Select Dates');
        setDate([]);
        setAssetSelectionList({});
        setSelectedOption(null);
        setInputSelectonText('');
    };

    const APICalledTripReport = async () => {
        const fromTime = moment(new Date(date[0])).startOf('day').format();
        const toTime = moment(new Date(date[1])).endOf('day').format();
        const deviceOid = assetSelectionList.uuid;
        try {
            const result = await APIGetTripReport(setLoading, token, agencyID, fromTime, toTime, deviceOid);
            if (result.data.status === 200 && result.data.data.length !== 0) {
                const { data } = result.data;
                if (data.tripList.length && data.tripsPerHour.length !== 0) {
                    navigate('/insights/odAnalytics/TripReport', {
                        replace: true,
                        state: {
                            fromTime: fromTime,
                            toTime: toTime,
                            tripData: data,
                        },
                    });
                    setLoading(false);
                } else {
                    setIncorrectCredentials(true);
                    setLoading(false);
                }
            } else {
                setIncorrectCredentials(true);
                setLoading(false);
            }
        } catch (err) {
            setIncorrectCredentials(true);
            setLoading(false);
        }
    };

    const APICalledSearchAssetNGroup = async (inputText) => {
        const params = {
            pageNumber: 1,
            totalPerPage: 100,
            searchKey: inputText,
        };
        try {
            const result = await APIGetDevices(setLoadAsset, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                const asset = data && data.length > 0 ? data.map((x) => ({
                    uuid: x.oid,
                    id: x.assetName,
                    name: x.deviceSerial ? x.deviceSerial : '',
                    pairId: x.oid,
                    pairedAssets: [],
                })) : [];
                const sortCompare = asset.length > 0 ? _.sortBy(asset, 'name') : [];
                setListAsset(sortCompare);
                if (state?.uuid) {
                    setSelectedOption(asset.findIndex((e) => e.uuid === uuid));
                    setAssetSelectionList(asset.find((e) => e.uuid === uuid));
                }
                setLoadAsset(false);
            } else {
                setListAsset([]);
                setLoadAsset(false);
            }
        } catch (err) {
            setLoadAsset(false);
        }
    };

     // this useEffect for call the searchAsset API called. Interval for 0.5 sec
     useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout); // this to clear time out
        }
        setSearchTimeout( // to buffer the search by 0.5 second
            setTimeout(() => {
            // APICalledSearchAssetNGroup(); // call the api
            APICalledSearchAssetNGroup(inputSelectonText);
        }, 500),
        );
        return () => clearTimeout(searchTimeout); // this also to clear time out
    }, [inputSelectonText]);

    useEffect(() => {
        if (token) {
            APICalledSearchAssetNGroup();
        }
        setNavigtionClick(5);
        setNavigationState('insights');
    }, []);

    useEffect(() => {
        APICalledSearchAssetNGroup('');
        setDropDownLoading(false);
    }, [dropDownLoading]);

    return (
        <div className="analytics-container">
            <ListNavigationTab list={assetPerformanceNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="analytics-header">
                <Title title="O/D Analytics" subtitle="Reports and insights to monitor and analyse origin / destination (O/D) movement patterns of assets / devices" titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
                <div className="analytics-config-tab">
                    <ConfigTab color="#FFFF" types={navigationList} module="assetPerformance" navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="analytics-body">
                <div className="analytics-body-title">
                    <Title title="Retrieve trip data for an asset / device" subtitle="Being by inputting search parameters below" titleSize="1.125rem" subtitleSize="0.875rem" lineHeight="1.75rem" subtitleLineHeight="1.25rem" />
                    <div className="anayltics-title-button-group">
                        <LiveMapButton label="Reset" width="4.4375rem" onClickFunction={() => reset()} />
                        <CreateWithIcon module="assetPerformance" label="Generate Report" buttonWidth="9.125rem" onClickFunction={() => APICalledTripReport()} disable={generateDisabled} loading={loading} />
                    </div>
                </div>
                <div className="analytics-body-form">
                    <div className="analytics-timeline-container">
                        <div className="analytics-timeline-text-container">
                            <div className="analytics-timeline-text">
                                Timeline
                            </div>
                        </div>
                        <DateRangePicker date={date} setDate={setDate} inputValue={inputValue} setInputValue={setInputValue} minDateRange={minDateRange} setMinDateRange={setMinDateRange} maxDateRange={maxDateRange} setMaxDateRange={setMaxDateRange} setApplyDatePicker={() => {}} dateRange={dateRange} setDateRange={setDateRange} />
                    </div>
                    <div className="analytics-asset-device-container">
                        <div className="analytics-timeline-text">
                            Asset / Device
                        </div>
                        <Autocomplete component="AssetPerformance" autocompleteComponent="AssetSearch" list={listAsset} setSelectionList={setAssetSelectionList} selectionList={assetSelectionList} text="Select asset / device" isImage isAgencySelector={false} stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} loadAsset={loadAsset} />
                    </div>
                </div>
            </div>
            {incorrectCredentials && (
                <div className="notification">
                    <SnackBar state={incorrectCredentials} setState={setIncorrectCredentials} title="Unable to Generate Report" subtitile="No data is found for the current search. Please try again with different search parameters." module="AssetPerformance" action="AssetPerformance" />
                </div>
            )}
        </div>
    );
};

export default ODAnalytics;
