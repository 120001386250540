import React from 'react';
import GeofenceEmptyIcon from './img/geofenceEmpty.svg';
import Text from '../text/Text';
import { CreateWithIcon } from '../buttonComponent/ButtonComponent';
import './tableemptystate.css';

const GeofenceTableEmptyState = (props) => {
    const { detailsPage } = props;

  return (
    <div className="empty-state-container">
      <img loading="lazy" src={GeofenceEmptyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
      <div className="empty-state-text">
          <Text text="No Geofence Policies" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
          <Text text="Your search did not match any geofence policies." size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
      </div>
      <div className="empty-state-button">
        <CreateWithIcon label="Create policy" module="policy" buttonHeight="44px" onClickFunction={detailsPage} />
      </div>
    </div>
  );
};

export default GeofenceTableEmptyState;
