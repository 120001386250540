import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import SearchInput from '../../../component/searchInput/SearchInput';
import { CreateWithIcon } from '../../../component/buttonComponent/ButtonComponent';
import Table from '../../../component/table/Table';
import NotificationTableEmptyState from '../../../component/tableEmptyState/NotificationTableEmptyState';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
import SnackBar from '../../../component/snackbar/Snackbar';
import './NotificationConfiguration.css';
import HorizonLogo from './img/horizonLogo.png';
import { APIGetAlertPolicies } from '../../../config/restAPI/FetchAPI';

const defautlParams = { pageNumber: 1 }; // this to default params for fetchAPI

const NotificationConfiguration = () => {
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');
    const navigate = useNavigate();
    const location = useLocation();
    const [triggerSnackbar, settriggerSnackbar] = useState(false); // trigger snackbar
    const [textTitleSnackbar, setTextTitleSnackbar] = useState(null);
    const [textSubSnackbar, setTextSubSnackbar] = useState(null);
    const [moduleSnackbar, setModuleSnackbar] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [showTable, setShowTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [notificationConfigData, setNotificationConfigData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [emptyState, setEmptyState] = useState(false);

    const notificationNavigationList = [
        {
            text: 'Notifications History', value: 1, path: '/notifications/history', enabled: true,
        },
        {
            text: 'Alert Configurations', value: 2, path: '/notifications/configuration', enabled: true,
        },
    ];

    const fetchAlertPolicies = async (params) => {
        try {
            const result = await APIGetAlertPolicies(setLoading, token, agencyID, params);
            const { data, totalData } = result.data;
            console.log(data);
            if (data && data.length > 0) {
                const reducer = data.map((x) => ({
                    oid: x.oid,
                    alertType: x.alertType,
                    alertName: x.name,
                    associatedTags: x.associatedTags,
                    notification: x.geofence?.notification ? x.geofence?.notification : {
                        enter: false,
                        leave: false,
                        generatePin: false,
                    },
                    description: x.description ? x.description : '',
                    isEnabled: x.isEnabled,
                    lastTrigger: x.lastTrigger,
                    geofenceArea: (x.geofence?.geofenceArea && x.geofence?.geofenceArea.length > 0) ? x.geofence?.geofenceArea.map((i) => ({
                        oid: i?.properties?.oid,
                        name: i?.properties?.name,
                        area: [{
                            type: i.type ? i.type : 'Feature',
                            properties: {
                                id: i?.properties?.oid,
                                name: i?.properties?.name,
                            },
                            id: i?.properties?.oid,
                            geometry: i?.geometry,
                        }],
                    })) : [],
                    notifiedUsers: x.notifiedUsers.length > 0 ? x.notifiedUsers.map((j) => ({
                        id: `${j.userFirstName} ${j.userLastName}`,
                        name: j.userEmail,
                        url: j.profileImageUrl ? j.profileImageUrl : null,
                        channel: j?.channels?.length > 0 ? j.channels : [],
                        oid: j.oid,
                    })) : [],
            }));
            console.log(reducer);
            setNotificationConfigData(reducer);
            setLoading(false);
            setTotalRows(totalData); // this for enable the next button for pagination
            }
        } catch (err) {
            setLoading(false);
        }
    };
    // this onChange for search text
    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    // this to go to details page, (Add)
    const detailsPage = () => {
        navigate('/notifications/configuration/details', { replace: true });
    };

    // click next button in table
    const handlePageChange = (page) => {
        const pagePerNumber = {
            pageNumber: page,
        };
        fetchAlertPolicies(pagePerNumber);
    };

    useEffect(() => {
        if (location.state) {
            if (location.state.snackbarStatus) {
                let timeout;
                settriggerSnackbar(true);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    settriggerSnackbar(false);
                }, 4000);
                setTextTitleSnackbar(location.state.snackbarTitle);
                setTextSubSnackbar(location.state.snackbarSub);
                setModuleSnackbar(location.state.snackbarStatus);
                navigate(location.pathname, { replace: true });
            }
        }
    }, [location.state]);

    useEffect(() => {
        fetchAlertPolicies();
    }, []);

    // this to render fetchGeofence once deleteGeofence
    useEffect(() => {
        if (location.state) {
            if (location.state.fetchAPICall) {
                fetchAlertPolicies(defautlParams);
                navigate(location.pathname, { replace: true }); // this to reset the state in location state
            }
            if (location.state.snackbarStatus) {
                let timeout;
                settriggerSnackbar(true);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    settriggerSnackbar(false);
                }, 4000);
                setTextTitleSnackbar(location.state.snackbarTitle);
                setTextSubSnackbar(location.state.snackbarSub);
                setModuleSnackbar(location.state.snackbarStatus);
                navigate(location.pathname, { replace: true });
            }
        }
    }, [location.state]);
    // useEffect(() => {
    //     if (!('Notification' in window)) {
    //         console.log('This browser does not support notifications');
    //     } else {
    //         Notification.requestPermission();
    //     }
    // }, []);

    // const handleNotificationBody = (title) => {
    //     if (Notification.permission === 'granted') {
    //         const options = {
    //             body: 'Test',
    //         };
    //         return new Notification(title, options);
    //     }
    //     console.log('Notification permission not granted');
    //     return null;
    // };

    return (
        <>
            <div className="notification-configuration-container">
                <ListNavigationTab list={notificationNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                <div className="notification-configuration-header">
                    <Title title="Alert Configurations" subtitle="Manage alerts that enable notifications or action triggers for devices/assets." titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
                </div>
                <div className="notification-configuration-body">
                    <div className="notification-configuration-body-filter">
                        <div className="notification-configuration-bulk-button-container">
                            <SearchInput containerWidth="25rem" text="Search" containerHeight="22px" value={searchText} onChangeText={onChangeSearchText} />
                        </div>
                        <div className="notification-configuration-input-filter-button">
                            <CreateWithIcon label="Create alert" module="policy" buttonHeight="44px" onClickFunction={detailsPage} />
                        </div>
                    </div>
                    {showTable && (
                        <div className={loading ? 'notification-configuration-body-text-loading' : 'notification-configuration-body-text'}>
                            <Table
                              selectedRowCount={selectedRowCount}
                              setSelectedRowCount={setSelectedRowCount}
                              tableData={notificationConfigData}
                              pagination
                              module="notification"
                              handlePageChange={handlePageChange}
                              loading={loading}
                              totalRows={totalRows}
                              backgroundColor="#F9FAFB"
                            />
                        </div>
                    )}
                    {emptyState && (
                        <div className="notification-configuration-body-empty">
                            <NotificationTableEmptyState detailsPage={detailsPage} test1="test" />
                        </div>
                    )}
                </div>
            </div>
            {
                triggerSnackbar && (
                <div className="geofence-snackbar-container">
                    <SnackBar state={triggerSnackbar} setState={settriggerSnackbar} title={textTitleSnackbar} subtitile={textSubSnackbar} module={moduleSnackbar} action={moduleSnackbar} />
                </div>
                )
            }
        </>
    );
};

export default NotificationConfiguration;
