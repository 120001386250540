import React, {
    useContext, useState, useEffect, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../config/reducer/Auth';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import Autocomplete from '../../component/autocomplete/Autocomplete';
import { ContinueButton, BackButton } from '../../component/buttonComponent/ButtonComponent';
import { APIWSDisconnect } from '../../config/restAPI/DeleteAPI';
import { APIWSConnect } from '../../config/restAPI/PostAPI';

import './agencyselector.css';

const AgencySelector = ({ history }) => {
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { login } = context;
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [selectionList, setSelectionList] = useState({});
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [agencyList, setAgencyList] = useState([]);
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDownLoading, setDropDownLoading] = useState(false);

    const allData = location?.state;
    const privileges = location?.state?.user?.privileges;

    // this to list back cattered to Autocomplete component
    const reducerSelector = (data) => {
        // console.log(data);
        const result = {
            id: data.role === 'System Administrator' ? 'admin' : data.agency.agencyId,
            name: data.agency.agencyName ? data.agency.agencyName : 'Admin Console',
            agencyUUID: data.agency.oid,
            chatBotEnabled: data.chatBotEnabled,
            isSuspended: data.agency.isSuspended,
            role: data.role,
            tgNotification: data.tgNotification,
        };
        return result;
    };

    // this to get the list of privileges from useLocation (routing)
    const privilegesLocation = useMemo(() => {
        const value = location?.state?.user?.privileges?.map((x) => reducerSelector(x));
        // console.log(value);
        return value;
    }, []);

    // this to get the token from useLocation (routing)
    const token = location?.state?.tokens;

    // this to add in the privileges into state. The reason of this useEffect are for list filtering
    useEffect(() => {
        if (privilegesLocation.length > 0) {
            setAgencyList(privilegesLocation);
        }
    }, [privilegesLocation]);

    // this function for continue button event
    const btnClickContinue = async (e) => {
        e.preventDefault();
        try {
            const websocketConnection = await APIWSConnect(token, selectionList.agencyUUID);
            // console.log(websocketConnection);
            // console.log({
            //     allData, selectionList, privileges,
            // });
            if (websocketConnection.status === 200) {
                login(allData, null, selectionList, privileges);
                if (selectionList.id === 'admin') {
                    navigate('/dashboard', { replace: true });
                } else {
                    navigate('/live/mapOverview', { replace: true });
                }
            } else {
                console.log('error on postResponse');
            }
        } catch (err) {
            // console.log(err);
        }
    };

    // this useEffect for button disabled
    // if selection list have a value the state button change to enabled.
    useEffect(() => {
        if (Object.keys(selectionList).length > 0) {
            setBtnDisabled(false);
        }
    }, [selectionList]);

    // this function for back button
    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };

    return (
    <div className="agency-selector-container">
        <div>
            <ImgWithTitle titleText="Agency Workspace" subText="You have access to multiple agency workspaces. Please select a workspace below to continue" icon="agency" />
            <div style={{ paddingTop: '1.25rem' }}>
                <Autocomplete list={agencyList} setSelectionList={setSelectionList} selectionList={selectionList} text="Select agency workspace" isImage={false} isAgencySelector stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} />
            </div>
            <div className="agency-selector-list-agency">Do not see your agency? Please contact your agency administrator for assistance.</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}><ContinueButton label="Continue" disable={btnDisabled} btnContinue={btnClickContinue} /></div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}><BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" /></div>
        </div>
    </div>
  );
};

export default AgencySelector;
