/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import _cloneDeep from 'lodash.clonedeep';
import { CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/system';
import _ from 'lodash';
import { useOutletContext, useLocation } from 'react-router-dom';
import { ExportCSVButton } from '../../../component/buttonComponent/ButtonComponent';
// import { liveNavigationList } from '../../../config/function/tabNavigationList';
import { APIGetSearchAssetnAssetGroup, APIGetDevices, APIGetV3Payload } from '../../../config/restAPI/FetchAPI';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import { downloadCSV } from '../../../config/function/exportCSV';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../component/title/Title';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Autocomplete from '../../../component/autocomplete/Autocomplete';
import TelemetryIcon from '../../../image/telemetryBreadCrumbs.svg';
import SearchInput from '../../../component/searchInput/SearchInput';
import TelemetrySShotEmptyState from '../../../component/tableEmptyState/TelemetrySShotEmptyState';
import Table from '../../../component/table/Table';
import { useApiData } from '../../../config/reducer/Modules';

import './telemetrySnapshot.css';
import { APIWSConnect } from '../../../config/restAPI/PostAPI';

const seperator = [
    <img loading="lazy" src={TelemetryIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Telemetry Snapshot</div>,
];

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

export default function TelemetrySnapshot() {
    const payloadContext = useContext(WebsocketContext);
    const {
    setChannel, data: websocketData, newPayloadId, selectedAssetInfo, selectAsset,
    } = payloadContext;
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agency-id');
    const agencyOid = Cookies.get('agencyUUID');
    const { apiResponse } = useApiData();
    const [assetSelectionList, setAssetSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [curDeviceOid, setCurDeviceOid] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
    const [loadAsset, setLoadAsset] = useState(false); // for getSearchAsset loader
    const [listAsset, setListAsset] = useState([]); // this is for list search asset dropdown
    const [telemValue, setTelemValue] = useState([]); // this is for telemetry value;
    const [loading, setLoading] = useState(false); // loading state
    const [uuid, setUuid] = useState('' || state?.uuid);
    const [showTable, setShowTable] = useState(false);
    const [emptyTable, setEmptyTable] = useState(false);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDownLoading, setDropDownLoading] = useState(false);

    const liveNavigationList = [
        {
            text: 'Map Overview', value: 1, path: '/live/mapOverview', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.liveMap,
        },
        {
            text: 'Telemetry Snapshot', value: 2, path: '/live/telemetrySnapshot', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.telemetrySnapshot,
        },
        // {
        //     text: 'Asset Status', value: 3, path: '/live/assetStatus', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.assetStatus,
        // },
    ];
    // this useEffect for onPageLoad
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        setNavigtionClick(2);
        setNavigationState('mapOverview');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const APISearchDevices = async (inputText) => {
        try {
            const params = {
                pageNumber: null,
                totalPerPage: 200,
                searchKey: inputText,
            };
            const result = await APIGetDevices(setLoadAsset, token, agencyOid, params);
            // console.log('result', result);
            if (result.status === 200) {
                const { data } = result.data;
                // console.log('data', data);
                const asset = data && data.length > 0 ? data.map((x) => ({
                  uuid: x.oid,
                  id: x.assetName,
                  name: x.deviceSerial ? x.deviceSerial : '',
                  pairId: x.deviceType,
                  pairedAssets: '',
                })) : [];
                const sortCompare = asset.length > 0 ? _.sortBy(asset, 'name') : [];
                if (state?.uuid) {
                    setSelectedOption(sortCompare.findIndex((e) => e.uuid === uuid));
                    setAssetSelectionList(sortCompare.find((e) => e.uuid === uuid));
                }
                // console.log('asset', asset);
                setListAsset(sortCompare);
                setLoadAsset(false);
            } else {
                setListAsset([]);
                setLoadAsset(false);
            }
        } catch (err) {
            // console.log(err);
            setListAsset([]);
            setLoadAsset(false);
        }
    };

    const APIGetPayload = async (deviceOid) => {
        setShowTable(false);
        setEmptyTable(false);
        const result = await APIGetV3Payload(setLoading, token, agencyOid, deviceOid);
        try {
            if (result.data.status === 200) {
                const { data } = result.data;
                setShowTable(true);
                setEmptyTable(false);
                setTelemValue(data);
                setLoading(false);
            } else if (result.status === 204) {
                setShowTable(false);
                setEmptyTable(true);
                setTelemValue([]);
                setLoading(false);
            } else {
                setShowTable(false);
                setEmptyTable(true);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const csvExport = () => {
        try {
            const data = telemValue.map((x) => ({
                traceId: x.traceId,
                sampleState: x.sampleState,
                latitude: x.latitude,
                longitude: x.longitude,
                latitudeSnap: x.latitudeSnap,
                longitudeSnap: x.longitudeSnap,
                lastSeenTimestamp: x.lastSeenTimestamp,
                address: x.address.localDisplay,
                heading: x.heading,
            }));
            downloadCSV(data, 'TelemetrySnapshot.csv');
        } catch (err) {
            console.log('Failed to export csv');
        }
    };

    useEffect(() => {
        APISearchDevices();
        if (state?.uuid) {
            setLoading(true);
        } else {
            setEmptyTable(true);
        }
        window.history.replaceState({}, document.title);
    }, []);

    useEffect(() => {
        if (Object.keys(assetSelectionList).length > 0) {
            APIGetPayload(assetSelectionList.uuid);
            setCurDeviceOid(assetSelectionList.uuid);
            // console.log('setSelectionOption', selectedOption);
            // setInputSelectonText(`${assetSelectionList.name} ${assetSelectionList.id} `);
        }
    }, [assetSelectionList]);

    // console.log(selectedOption);
    useEffect(() => {
        if (searchTimeout) {
          clearTimeout(searchTimeout); // this to clear time out
        }
        setSearchTimeout( // to buffer the search by 0.5 second
          setTimeout(() => {
            // APICalledSearchAssetNGroup(); // call the api
            APISearchDevices(inputSelectonText);
          }, 500),
        );
        return () => clearTimeout(searchTimeout); // this also to clear time out
      }, [inputSelectonText]);

    // this to get latest websocket based on the selected devices
    useEffect(() => {
        if (newPayloadId) {
            if (curDeviceOid) {
                if (websocketData.type === 'telemetry') {
                    if (websocketData.data.deviceOid === curDeviceOid) {
                        setShowTable(true);
                        setEmptyTable(false);
                        const cloneData = _cloneDeep(telemValue);
                        cloneData.unshift(websocketData.data);
                        setTelemValue([...cloneData]);
                    }
                }
            } else {
                setShowTable(true);
                setEmptyTable(false);
                const cloneData = _cloneDeep(telemValue);
                cloneData.unshift(websocketData.data);
                setTelemValue([...cloneData]);
            }
        }
        // if (newPayloadId && websocketData.deviceOid === curDeviceOid) {
        //     const cloneData = _cloneDeep(telemValue);
        //     console.log(websocketData);
        //     cloneData.unshift(websocketData);
        //     setTelemValue([...cloneData]);
        // }
    }, [newPayloadId]);

    useEffect(() => {
        if (listAsset.length < 1) {
          APISearchDevices('');
        }
        setDropDownLoading(false);
    }, [dropDownLoading]);

    const CustomLoader = () => (
        <div style={{ padding: '24px', margin: 'auto' }}>
            <ThemeProvider theme={loadTheme}>
                <CircularProgress color="amcolor" variant="indeterminate" />
            </ThemeProvider>
        </div>
    );

    const WSReconnect = async () => {
        const postResponse = await APIWSConnect(token, agencyOid);
        if (postResponse.status === 200) {
            setChannel(agencyID);
        }
    };

    useEffect(() => {
        if (idleState) {
            WSReconnect();
            setIdleState(false);
        }
    }, []);

    return (
    <div className="telemetry-snapshot-container" style={{ width: windowSize.innerWidth - 280 }}>
        <ListNavigationTab list={liveNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
        <div className="telemetry-header">
            <BreadCrumbs customSeperator={seperator} />
            <Title title="Telemetry Snapshot" subtitle="Quickly monitor real-time incoming telemetry for an asset. " titleSize="1.875rem" />
        </div>
        <div className="telemetry-body">
                <div className="telemetry-body-filter">
                    <div className="dropdown-button-container">
                    <Autocomplete autocompleteComponent="AssetSearch" list={listAsset} setSelectionList={setAssetSelectionList} selectionList={assetSelectionList} text="Select device / asset" isImage={false} isAgencySelector={false} stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} loadAsset={loadAsset} />
                    </div>
                    <div className="telemetry-filter-button">
                        {/* <SearchInput containerWidth="25rem" text="Search" containerHeight="22px" /> */}
                        <ExportCSVButton label="Export View" buttonHeight="44px" onClickFunction={csvExport} disable={telemValue.length < 1} />
                    </div>
                </div>
                {/* {
                    loading === true ? (
                        <CustomLoader />
                    ) : (
                        telemValue.length > 0 ? (
                            <div className="telemetry-body-text">
                                <Table
                                  selectedRowCount={selectedRowCount}
                                  setSelectedRowCount={setSelectedRowCount}
                                  tableData={telemValue}
                                  checkbox={false}
                                  loading={loadAsset}
                                  pagination={false}
                                  module="telemetry"
                                  backgroundColor="#F9FAFB"
                                />
                            </div>
                        ) : (
                            <div className="telemetry-body-empty">
                                <TelemetrySShotEmptyState />
                            </div>
                        )
                    )
                } */}
                {
                    loading && (
                        <div className="telemetry-body-empty">
                            <CustomLoader />
                        </div>
                    )
                }
                {
                    showTable && (
                        <div className="telemetry-body-text">
                            <Table
                              selectedRowCount={selectedRowCount}
                              setSelectedRowCount={setSelectedRowCount}
                              tableData={telemValue}
                              checkbox={false}
                              loading={loading}
                              pagination={false}
                              module="telemetry"
                              backgroundColor="#F9FAFB"
                            />
                        </div>
                    )
                }
                {emptyTable && (
                    <div className="telemetry-body-empty">
                        <TelemetrySShotEmptyState />
                    </div>
                )}
        </div>
    </div>
    );
}
