/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, {
    useEffect, useState, useContext, useCallback, useRef, useLayoutEffect, Components,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Cookies from 'js-cookie';
import {
    BackButton,
    PrimaryButton,
    SecondaryButton,
    OutlinedDeleteButton,
    CreateWithIcon,
} from '../../../../component/buttonComponent/ButtonComponent';
import { CountriesCallingCode } from '../../../../component/countriesDropdown/CountriesDropdown';
import { APIGetV3TeamMembers } from '../../../../config/restAPI/FetchAPI';
import { APIUpdateTeamMember, APITransferOwnership } from '../../../../config/restAPI/PatchAPI';
import { APIDeleteTeamMember } from '../../../../config/restAPI/DeleteAPI';
import TextInput from '../../../../component/textInput/TextInput';
import Title from '../../../../component/title/Title';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import Autocomplete from '../../../../component/autocomplete/Autocomplete';
import Checkbox from '../../../../component/checkbox/Checkbox';
import InputTags from '../../../../component/inputTags/InputTags';
import HelpIcon from './img/helpCircle.svg';
import TransferIcon from './img/transferIcon.svg';
import TransferIconDisabled from './img/transferIconDisabled.svg';
import Modal from '../../../../component/modal/Modal';
import ToolTips from '../../../../component/tooltip/ToolTips';
import './updateTeamMember.css';

const access = [
    {
        name: 'Viewer',
        value: 'Agency_Viewer',
    },
    {
        name: 'Editor',
        value: 'Agency_Editor',
    },
    {
        name: 'Admin',
        value: 'Agency_Administrator',
    },
];

const UpdateTeamMember = () => {
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const switchRoleName = (value) => {
        switch (value) {
            case 'Agency Owner':
                return 'Owner';
            case 'Agency Administrator':
                return 'Admin';
            case 'Agency Viewer':
                return 'Viewer';
            case 'Agency Editor':
                return 'Editor';
            default:
                return value;
        }
    };

    const loggedInRole = switchRoleName(Cookies.get('role'));
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const loggedInId = Cookies.get('_id');

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const navigate = useNavigate();
    const { state } = useLocation();
    const { row } = state;
    const { member } = row;
    const { firstName, lastName } = member;

    const userId = row.id;
    const callingCodeRef = useRef(null);
    const assetPerformanceCheckbox = useRef(null);
    const disabledTransfer = (loggedInRole !== 'Owner');
    const index = access.findIndex((role) => role.name === row.accessPrivileges);
    const [selectionList, setSelectionList] = useState({ name: row.accessPrivileges });
    const [accessRow, setAccessRow] = useState(row.accessPrivileges);
    const [accessPrivilege, setAccessPrivilege] = useState(access);
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(index);
    const [switchModalShow, setSwitchModalShow] = useState(false);
    const [accessPrivilegeList, setAccessPrivilegeList] = useState([]);
    const [fleetManagementModules, setFleetMangementModules] = useState(row.modules.fleetManagement ? {
        asset: row.modules.fleetManagement.asset ? row.modules.fleetManagement.asset : false,
        beacon: row.modules.fleetManagement.beacon ? row.modules.fleetManagement.beacon : false,
        geofence: row.modules.fleetManagement.geofence ? row.modules.fleetManagement.geofence : false,
        connectivity: row.modules.fleetManagement.connectivity ? row.modules.fleetManagement.connectivity : false,
    } : {});
    const [insightsModule, setInsightsModule] = useState(row.modules.insights ? {
        assetPerformance: row.modules.insights.assetPerformance ? row.modules.insights.assetPerformance : false,
        iorail: row.modules.insights.iorail ? row.modules.insights.iorail : false,
        ioroad: row.modules.insights.ioroad ? row.modules.insights.ioroad : false,
        odAnalytics: row.modules.insights.odAnalytics ? row.modules.insights.odAnalytics : false,
    } : {});
    const [liveModules, setLiveModules] = useState(row.modules.live ? {
        assetStatus: row.modules.live.assetStatus ? row.modules.live.assetStatus : false,
        liveMap: row.modules.live.liveMap ? row.modules.live.liveMap : false,
        telemetrySnapshot: row.modules.live.telemetrySnapshot ? row.modules.live.telemetrySnapshot : false,
    } : {});
    const [teamMembers, setTeamMembers] = useState([]);
    const [transferOwner, setTransferOwner] = useState({});

    const [timezoneCountryCode, setTimezoneCountryCode] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState(row.tags);
    const [removedTags, setRemovedTags] = useState([]);
    const [tagsHistory, setTagsHistory] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState(row.phoneNumber === null ? '' : row.phoneNumber);
    const [phoneNumberInput, setPhoneNumberInput] = useState(false);

    // console.log(row);

    const [selectedCountry, setSelectedCountry] = useState({
        value: 'MY',
        label: 'Malaysia',
        icons: 'https://hatscripts.github.io/circle-flags/flags/my.svg',
    });

    // Calling code dropdown
    const [listCallingCode, setListCallingCode] = useState([]);
    const [countryCallingCodeSelected, setCountryCallingCodeSelected] = useState({
        id: 'MY',
        name: 'Malaysia',
        value: '+60',
    });

    const [dropDownLoading, setDropDownLoading] = useState(false);

    useEffect(() => {
        if (selectedCountry.value) {
            setTimezoneCountryCode(selectedCountry.value);
        }
    }, [selectedCountry]);

    const heightPercentage = (ref) => {
        let heightState;
        if (ref.current !== null) {
            const percentage = (ref.current.offsetTop / windowSize.innerHeight) * 100;
            if (percentage > 60) {
                heightState = true;
            } else {
                heightState = false;
            }
        }
        return heightState;
    };

    const handleOnClick = (value, module, setState) => {
        setState({ ...module, [value]: !module[value] });
    };

    const handleBackButton = () => {
        navigate('/settings/teams', { replace: true });
    };

    const updateTeamMemberDetails = async () => {
        const userOid = row.id;
        const params = {
            team: {
                userOid: userOid,
                role: selectionList.value,
                addTags: tags,
                removeTags: removedTags,
                module: {
                    live: liveModules,
                    insights: insightsModule,
                    fleetManagement: fleetManagementModules,
                },
            },
        };
        try {
            const result = await APIUpdateTeamMember(token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                setLoading(false);
                navigate('/settings/teams', { replace: true });
            } else {
                const { data } = result.data;
                // console.log('unsuccessfully updated: ', data);
            }
            setLoading(false);
        } catch (err) {
            // console.log(err);
            setLoading(false);
        }
        // console.log(fleetManagementModules);
    };

    const fetchTeamMembers = async (page, perPage) => {
        try {
            const params = {
                pageNumber: page,
                totalPerPage: perPage,
            };
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x) => ({
                        name: `${(x.userFirstName !== undefined) ? (x.userFirstName) : (null)} ${(x.userLastName !== undefined) ? (x.userLastName) : (null)}`,
                        oid: x.oid,
                        id: x.userEmail,
                    })) : [];
                    const filteredData = reducer.filter((x) => (x.name !== 'null null' && x.oid !== userId));
                    setTeamMembers(filteredData);
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const openSwitchModal = () => {
        // console.log(switchModalShow);
        setSwitchModalShow(!switchModalShow);
        setTransferOwner({});
    };

    const openDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const deleteFunction = async () => {
        const params = {
            userOids: [
                row.id,
            ],
        };
        try {
            const result = await APIDeleteTeamMember(token, agencyID, params);
            if (result.status === 200) {
                setDeleteModal(false);
                navigate('/settings/teams', { replace: true });
            } else {
                const { data } = result;
                // console.log('unsuccessfully updated: ', data);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const buttonGroup = () => (
        <>
        <SecondaryButton
          label="Cancel"
          width="5rem"
          fontSizze="0.875rem"
          onClickFunction={() => handleBackButton()}
        />
        {loggedInId === userId || row.accessPrivileges === 'Owner' ? (
            <ToolTips text="You cannot remove yourself and/or an agency owner">
                <div className="delete-btn">
                <OutlinedDeleteButton
                  label="Remove User"
                  buttonHeight="2.75rem"
                  disable={loggedInId === row.id ? 'disabled' : ''}
                />
                </div>
            </ToolTips>
        ) : (
            <OutlinedDeleteButton
              label="Remove User"
              buttonHeight="2.75rem"
              onClickFunction={() => openDeleteModal()}
              disable={loggedInRole === 'Viewer' ? 'disabled' : ''}
            />
        )}
        <CreateWithIcon
          buttonHeight="2.75rem"
          label="Save"
          loading={loading}
          module="assetPerformance"
          width="4.0625rem"
          disable={loggedInRole === 'Viewer' ? 'disabled' : ''}
          onClickFunction={() => updateTeamMemberDetails()}
        />
        {
                deleteModal && (
                    <div className="modal-container-table-bg">
                        <Modal setSwitchModalShow={setDeleteModal} switchModalShow={deleteModal} btnConfirm={deleteFunction} module="DeleteModal" source="Teams" dataRow={row} />
                    </div>
                )
            }
        </>
    );

    const submitTransferOwner = async () => {
        // console.log(transferOwner);
        const params = {
            userOid: transferOwner.oid,
        };

        try {
            const result = await APITransferOwnership(token, agencyID, params);
            if (result.data.status === 200) {
                setSwitchModalShow(!switchModalShow);
                navigate('/settings/teams', { replace: true });
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const switchDropDownList = (value) => {
        switch (value) {
            case 'Owner':
                return (
                    <>
                        <div className="member-owner-access-privilege-container">
                            <div className="owner-input">
                                <input name="assetName" disabled className="owner-help-input" value={row.accessPrivileges} />
                                <ToolTips text="Help Icon">
                                    <img src={HelpIcon} alt="owner-help-icon" className="owner-help-icon" loading="lazy" />
                                </ToolTips>
                            </div>
                            <button
                              type="button"
                              onClick={() => openSwitchModal()}
                              className="transfer-ownership-btn"
                              disabled={disabledTransfer}
                            >
                                <img src={disabledTransfer ? (TransferIconDisabled) : (TransferIcon)} alt="transfer-icon" loading="lazy" />
                                <div className="transfer-ownership-text">Transfer Ownership</div>
                            </button>
                            {
                            switchModalShow && (
                                <Modal accessPrivilege={teamMembers} switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} module="TransferOwnership" transferOwner={transferOwner} setTransferOwner={setTransferOwner} btnConfirm={submitTransferOwner} />
                            )
                            }
                        </div>
                    </>
                );
            default:
                return (
                <>
                    <div className="member-access-privilege-container">
                        <Autocomplete list={accessPrivilege} setSelectionList={setSelectionList} selectionList={selectionList} isImage={false} stateInputValue={setInputSelectonText} width="30.25rem" selectedOption={selectedOption} setSelectedOption={setSelectedOption} disable={(loggedInId === userId)} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} />
                    </div>
                </>
                );
        }
    };

    const switchAccessPrivilegeModule = (value) => {
        switch (value) {
            case 'Admin':
                return (
                    <>
                    </>
                );
            case 'Owner':
                return (
                    <>
                    </>
                );
            default:
                return (
                    <>
                        <div className="member-form-row">
                            <div className="member-input-label">
                                Live
                            </div>
                            <div className="member-checkbox-row">
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Live Map"
                                      width="20px"
                                      height="20px"
                                      value="liveMap"
                                      onClick={(e) => handleOnClick(e.target.value, liveModules, setLiveModules)}
                                      checked={liveModules.liveMap}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Telemetry Snapshot"
                                      width="20px"
                                      height="20px"
                                      value="telemetrySnapshot"
                                      onClick={(e) => handleOnClick(e.target.value, liveModules, setLiveModules)}
                                      checked={liveModules.telemetrySnapshot}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Asset Status"
                                      width="20px"
                                      height="20px"
                                      value="assetStatus"
                                      onClick={(e) => handleOnClick(e.target.value, liveModules, setLiveModules)}
                                      checked={liveModules.assetStatus}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="member-form-row">
                            <div className="member-input-label">
                                Insights
                            </div>
                            <div className="member-checkbox-row">
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Asset Performance"
                                      width="20px"
                                      height="20px"
                                      value="assetPerformance"
                                      onClick={(e) => handleOnClick(e.target.value, insightsModule, setInsightsModule)}
                                      checked={insightsModule.assetPerformance}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="IOrail"
                                      width="20px"
                                      height="20px"
                                      value="iorail"
                                      onClick={(e) => handleOnClick(e.target.value, insightsModule, setInsightsModule)}
                                      checked={insightsModule.iorail}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="IOroad"
                                      value="ioroad"
                                      width="20px"
                                      height="20px"
                                      onClick={(e) => handleOnClick(e.target.value, insightsModule, setInsightsModule)}
                                      checked={insightsModule.ioroad}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="ODAnalytics"
                                      value="odAnalytics"
                                      width="20px"
                                      height="20px"
                                      onClick={(e) => handleOnClick(e.target.value, insightsModule, setInsightsModule)}
                                      checked={insightsModule.odAnalytics}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="member-form-row">
                            <div className="member-input-label">
                                Fleet Management
                            </div>
                            <div className="member-checkbox-row">
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Assets & Devices"
                                      width="20px"
                                      height="20px"
                                      value="asset"
                                      onClick={(e) => handleOnClick(e.target.value, fleetManagementModules, setFleetMangementModules)}
                                      checked={fleetManagementModules.asset}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Beacons"
                                      value="beacon"
                                      width="20px"
                                      height="20px"
                                      onClick={(e) => handleOnClick(e.target.value, fleetManagementModules, setFleetMangementModules)}
                                      checked={fleetManagementModules.beacon}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Geofence"
                                      value="geofence"
                                      width="20px"
                                      height="20px"
                                      onClick={(e) => handleOnClick(e.target.value, fleetManagementModules, setFleetMangementModules)}
                                      checked={fleetManagementModules.geofence}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                      text="Connectivity"
                                      value="connectivity"
                                      width="20px"
                                      height="20px"
                                      onClick={(e) => handleOnClick(e.target.value, fleetManagementModules, setFleetMangementModules)}
                                      checked={fleetManagementModules.connectivity}
                                      disabled={(loggedInId === userId ? 'disabled' : '')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="member-form-row">
                            <div className="member-input-label">
                                Tags
                                <div className="member-subtitle">
                                    Grant permission to tagged resources. Leave blank to grant permission to all resources
                                </div>
                            </div>
                            <InputTags tags={tags} setTags={setTags} removedTags={removedTags} setRemovedTags={setRemovedTags} tagsHistory={tagsHistory} disabled={(loggedInRole === userId)} />
                        </div>
                    </>
                );
        }
    };

    useEffect(() => {
        if (token) {
            fetchTeamMembers(1, 100);
        }
    }, []);

    return (
        <div className="update-main-content">
            <div className="update-back-button">
                <BackButton label="Back to team members" btnClickBack={() => handleBackButton()} color="#FCFCFD" />
            </div>
            <div className="update-form">
                <div className="update-header">
                    <div className="update-title">
                        <Title title="Update team member" titleSize="1.125rem" lineHeight="1.75rem" gap="0.25rem" deleteSubtitle>
                            {firstName}
                            {' '}
                            {lastName}
                            {' '}
                            •
                            {' '}
                            {member.email}
                        </Title>
                        <div className="update-button-group">
                            {buttonGroup()}
                        </div>
                    </div>
                    <ColoredLine />
                </div>
                <div className="update-member-details">
                    <div className="member-form-row">
                        <div className="member-input-label">
                            Name
                        </div>
                        <div className="member-name-container">
                            <div className="member-name-field">
                                <div className="member-name-label">
                                    First name
                                </div>
                                <input name="assetName" disabled className="member-input-box" value={firstName} />
                            </div>
                            <div className="member-name-field">
                                <div className="member-name-label">
                                    Last name
                                </div>
                                <input name="assetName" disabled className="member-input-box" value={lastName} />
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="member-form-row">
                        <div className="member-input-label">
                            Email address
                        </div>
                        <div className="member-email-container">
                            <TextInput label="Forgot" placeholder={member.email} name="email" type="text" width="32rem" error={false} disable />
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="member-form-row">
                        <div className="member-input-label" ref={callingCodeRef}>
                            Phone number
                        </div>
                        <div className="member-phone-number-container">
                            <CountriesCallingCode
                              list={listCallingCode}
                              setSelectionList={setCountryCallingCodeSelected}
                              selectionList={countryCallingCodeSelected}
                              text="MY"
                              isImage
                              stateInputValue={setInputSelectonText}
                              width="4.6rem"
                              lowPosition={heightPercentage(callingCodeRef)}
                              message={message}
                              setMessage={setMessage}
                              disabled
                              value={phoneNumberValue}
                              initialValue={member.contactCode}
                              setPhoneNumberValue={setPhoneNumberValue}
                              setPhoneNumberInput={setPhoneNumberInput}
                            />
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="member-form-row">
                        <div className="member-input-label">
                            Access Privilege
                        </div>
                        {switchDropDownList(row.accessPrivileges)}
                    </div>
                    {switchAccessPrivilegeModule(selectionList.name)}
                    <ColoredLine />
                    <div className="button-end-justify">
                        <div className="update-button-group">
                            {buttonGroup()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateTeamMember;
