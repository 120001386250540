/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from './img/dashboard.svg';
import LiveIcon from './img/live.svg';
import './navigation.css';
// import LiveIcon from '../../image/liveIcon.svg';
import NotificationBadge from '../notification/NotificationBadge';
import Agencies from './img/agencies.svg';
import InsightsIcon from './img/insights.svg';
import Inventory from './img/inventory.svg';
import Notifications from './img/notifications.svg';
import SettingsIcon from './img/settings.svg';
import SupportIcon from './img/support.svg';

const Navigation = (props) => {
    const navigate = useNavigate();
    const {
      text, navigationState, setNavigationState, value, path, setNavigationClick, showMenu, setShowMenu, data,
    } = props;

    const stateChange = (e, nameValue) => {
        // e.preventDefault();
        setNavigationState(nameValue);
        navigate(path, { replace: true });
        setNavigationClick(1);
        // console.log('nameValue', nameValue);
    };

    function navIcon() {
        switch (text) {
            case 'Dashboard':
                return <img loading="lazy" src={DashboardIcon} className="icon-nav" alt="nav-icon-dashboard" />;
            case 'Live':
                return <img loading="lazy" src={LiveIcon} className="icon-nav" alt="nav-icon-live" />;
            case 'Insights':
                return <img loading="lazy" src={InsightsIcon} className="icon-nav" alt="nav-icon-insights" />;
            case 'Settings':
                return <img loading="lazy" src={SettingsIcon} className="icon-nav" alt="nav-icon-settings" />;
            case 'Support':
                return <img loading="lazy" src={SupportIcon} className="icon-nav" alt="nav-icon-support" />;
            case 'Inventory':
                return <img loading="lazy" src={Inventory} className="icon-nav" alt="nav-icon-inventory" />;
            case 'Notifications':
                return <img loading="lazy" src={Notifications} className="icon-nav" alt="nav-icon-notifications" />;
            case 'Agencies':
                return <img loading="lazy" src={Agencies} className="icon-nav" alt="nav-icon-agencies" />;
            default:
                return null;
        }
    }

    return (
    <div
      className="nav-container-color"
      onMouseEnter={(e) => e.target.id === 'Notifications' && setShowMenu(true)}
    //   onMouseEnter={(e) => setShowMenu(true)}
      onMouseLeave={(e) => e.target.id === 'Notifications' && setShowMenu(false)}
    //   onMouseLeave={(e) => setShowMenu(false)}
      id={text}
      style={navigationState === value
        ? { background: '#F9FAFB', cursor: 'pointer' } : { background: '#FFFFFF', cursor: 'pointer' }}
      onClick={(e) => stateChange(e, value)}
    >
        <div className="nav-justify">
            <div className="icon-nav-container">
                {navIcon()}
            </div>
            <div
              className="text-nav"
              style={navigationState === value
            ? { color: '#101828' } : { color: '#344054' }}
              onClick={(e) => stateChange(e, value)}
            >
                {text}
            </div>
        </div>
        {text === 'Notifications' && (
            <NotificationBadge text={data ? data.filter((x) => x.read === false).length : 0} />
        )}
    </div>

    );
};

export default Navigation;
