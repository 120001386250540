import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { APIGetAgencies } from '../../../config/restAPI/FetchAPI';
import Table from '../../../component/table/Table';
import { agenciesNavigationList } from '../../../config/function/tabNavigationList';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import { BulkActionButton, CreateWithIcon } from '../../../component/buttonComponent/ButtonComponent';
import SearchInput from '../../../component/searchInput/SearchInput';
import FilterButton from '../../../component/filterButton/FilterButton';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../component/title/Title';
import BuildingIcon from '../../../image/building.svg';
import './agencies.css';

const seperator = [
    <img loading="lazy" src={BuildingIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Agencies</div>,
];

const Agencies = () => {
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const [navigtionClick, setNavigtionClick] = useOutletContext();

    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [agencyData, setAgencyData] = useState([]);
    const bulkActionDisabled = selectedRowCount <= 1;

    const fetchAgencies = async (page) => {
        try {
            const result = await APIGetAgencies(setLoading, token, agencyID, page, perPage);
            // console.log(result);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log(data);
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x) => ({
                        oid: x.oid,
                        agencyId: x.agencyId,
                        agencyName: x.agencyName,
                        totalDevices: x.totalDevices,
                        totalUsers: x.totalUsers,
                        status: x.status,
                        lastLogin: {
                            date: Object.prototype.hasOwnProperty.call(x, 'lastLogin') ? (x.lastLogin.date) : (null),
                            address: Object.prototype.hasOwnProperty.call(x, 'lastLogin') ? (x.lastLogin.address) : (null),
                        },
                        MaasServices: x.services,
                        address: {
                            city: x.address.city !== null ? (x.address.city) : (null),
                            country: x.address.country !== null ? (x.address.country) : (null),
                            postcode: x.address.postcode !== null ? (x.address.postcode) : (null),
                            state: x.address.state !== null ? (x.address.state) : (null),
                            street: x.address.street !== null ? (x.address.street) : (null),
                            countryCode: x.address.countryCode !== null ? (x.address.countryCode) : (''),
                        },
                        timezone: x.timezone,
                    })) : [];
                    setAgencyData(reducer);
                    setTotalRows(result.data.totalData);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    // console.log(agencyData);

    const handlePageChange = (page) => { fetchAgencies(page); };

    const createAgencyNavigate = () => {
        navigate('/agencies/createAgency', { replace: true });
    };

    useEffect(() => {
        if (token) {
            fetchAgencies(1);
        }
    }, []);

    return (
        <div className="agencies-container">
            <ListNavigationTab list={agenciesNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="agencies-header">
                <BreadCrumbs customSeperator={seperator} />
                <Title title="Agencies" subtitle="Manage agencies, its associated workspace and subscriptions" titleSize="1.875rem" />
            </div>
            <div className="agencies-body">
                <div className="agencies-body-filter">
                    <div className="agencies-bulkAction-button-container">
                        <BulkActionButton label="Bulk Action" width="8.1875rem" height="2.75rem" color="white" disable={bulkActionDisabled} />
                    </div>
                    <div className="agencies-filter-button">
                        <SearchInput containerWidth="25rem" text="Search" />
                        <FilterButton height="2.75rem" />
                        <CreateWithIcon label="Create agency" module="policy" buttonHeight="2.75rem" onClickFunction={() => createAgencyNavigate()} />
                    </div>
                </div>
                <div className="agencies-body-text">
                    <Table
                      selectedRowCount={selectedRowCount}
                      setSelectedRowCount={setSelectedRowCount}
                      tableData={agencyData}
                      checkbox
                      pagination
                      module="agencies"
                      handlePageChange={handlePageChange}
                      loading={loading}
                      totalRows={totalRows}
                      backgroundColor="#F9FAFB"
                    />
                </div>
            </div>
        </div>
    );
};

export default Agencies;
