/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import InviteUsers from '../../../image/usersPlus.svg';
import CloseButton from '../../../image/xButton.svg';
import AddRemoveInputForm from '../../addRemoveInput/addRemoveInputForm';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import './InviteUsersModal.css';

const InviteUsersModal = (props) => {
    const {
        switchModalShow,
        openSwitchModal,
        setSwitchModalShow,
        emailList,
        setEmailList,
        btnConfirm,
        selectionList, setSelectionList,
        source,
        agencyData,
        agencyName,
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        bgcolor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 0,
        boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        borderRadius: '12px',
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const access = [
        {
            name: 'Viewer',
            value: 'Agency_Viewer',
        },
        {
            name: 'Editor',
            value: 'Agency_Editor',
        },
        {
            name: 'Admin',
            value: 'Agency_Administrator',
        },
    ];
    const [accessPrivilege, setAccessPrivilege] = useState(access);
    // const [selectionList, setSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const emailListState = emailList.map((x) => isValidEmail(x.email));
    const btnDisabled = emailListState.some((x) => !x);
    const [dropDownLoading, setDropDownLoading] = useState(false);

    const stateChange = () => {
        // console.log('modal should close');
        setSwitchModalShow(!switchModalShow);
        setSelectionList({});
    };

    const switchModalContent = (text) => {
        switch (text) {
            case 'agencies':
                return (
                    <>
                    Users will be invited to the
                    {' '}
                    <b>
                    {agencyData.agencyName}
                    </b>
                    {' '}
                    agency workspace.
                    </>
                );
            case 'agenciesInviteOwner':
                return (
                    <>
                    The first user added to the
                    {' '}
                    <b>
                    {agencyData.agencyName}
                    </b>
                    {' '}
                    agency workspace will be designated Agency Owner by default.
                    </>
            );
        case 'teams':
            return (
                <>
                Increase operational transparency. Invite your colleagues to the
                {' '}
                <b>
                {agencyName}
                </b>
                {' '}
                agency workspace.
                </>
            );
            default:
                return (
                    <>
                    Increase operational transparency. Invite your colleagues to the
                    {' '}
                    <b>
                    Asia Mobiliti
                    </b>
                    {' '}
                    agency workspace.
                    </>
                );
        }
    };

    return (
        <Modal
          open={switchModalShow}
          onClose={openSwitchModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: 'blur(8px)',
            backgroundColor: '#344054B2',
          }}
        >
            <Box sx={style}>
                <div className="user-modal-header">
                    <div className="icon-button-container">
                        <div className="icon-container">
                            <img style={{ margin: 'auto' }} src={InviteUsers} alt="invite-users-icon" loading="lazy" />
                        </div>
                        <div className="x-button-container">
                            <div type="button" className="user-x-button" onClick={(e) => stateChange(e)}>
                                <img src={CloseButton} alt="close-modal" />
                            </div>
                        </div>
                    </div>
                    <Title title="Invite users" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0.25rem" lineHeight="1.75rem">
                        {switchModalContent(source)}
                    </Title>
                </div>
                <div className="user-modal-content">
                    <div className="user-modal-text">
                        Email address
                    </div>
                    <AddRemoveInputForm emailList={emailList} setEmailList={setEmailList} source={source} />
                </div>
                {source !== 'agenciesInviteOwner' ? (
                    <div className="access-privilege-container">
                        <div className="user-modal-text">
                            Access Privilege
                        </div>
                        <Autocomplete list={accessPrivilege} setSelectionList={setSelectionList} selectionList={selectionList} text="Select Access Privilege" isImage={false} stateInputValue={setInputSelectonText} width="20.375rem" selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} />
                    </div>
                ) : (
                    <>
                    </>
                )}
                <div className="users-button-container">
                    <SecondaryButton label="Cancel" width="170px" onClickFunction={(e) => stateChange(e)} />
                    <PrimaryButton label={source === 'agenciesInviteOwner' ? 'Send Invite' : 'Send Invites'} width="170px" onClickFunction={() => btnConfirm()} disable={btnDisabled} />
                </div>
            </Box>
        </Modal>
    );
};

export default InviteUsersModal;
