import React from 'react';
import FilterIcon from './img/filter-lines.svg';
import './filterButton.css';

export default function FilterButton(props) {
  const { actionFunction, height } = props;
  return (
    <button type="button" className="filter-button" style={{ height: height }} onClick={() => actionFunction}>
        <img loading="lazy" src={FilterIcon} alt="filterIcon" />
        <div className="filter-text">Filters</div>
    </button>
  );
}
